'use client';

import LoginButton from '@/components/atoms/login-button';
import RenewButton from '@/components/atoms/renew-button';
import Button from '@/components/molecules/button';
import CalendlyDialog from '@/components/molecules/calendly-dialog';
import Dialog from '@/components/molecules/dialog';
import {
  useAppState,
  useResetAppState,
} from '@/components/providers/state-provider';
import { fetcher, isError } from '@/lib/helpers';
import {
  Announcement,
  ErrorResponse,
  ShowOn,
  User,
  UserStatus,
} from '@/types/sermons';
import { default as classNames, default as cx } from 'classnames';
import moment from 'moment-timezone';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';
import {
  BsCaretDownFill,
  BsList,
  BsPersonFill,
  BsXCircle,
} from 'react-icons/bs';
import useSWR from 'swr';
import './globals.scss';

export default function Header({
  announcements: parentAnnouncements = [],
}: {
  announcements?: Announcement[];
}) {
  const resetAppState = useResetAppState();

  const [tutorialOpen, setTutorialOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const {
    state: { user, token, couponOpen, isBrowser },
    dispatch,
  } = useAppState();

  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const userMenuButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const menuEventHandler = (e: MouseEvent) => {
      if (!menuButtonRef.current?.contains(e.target as Node)) {
        setMenuOpen(false);
      }
      if (!userMenuButtonRef.current?.contains(e.target as Node)) {
        setUserMenuOpen(false);
      }
    };
    document.addEventListener('click', menuEventHandler);

    return () => {
      document.removeEventListener('click', menuEventHandler);
    };
  }, []);

  const { data: swrUser, isLoading } = useSWR<User | ErrorResponse>(
    [
      token ? `${process.env.NEXT_PUBLIC_API_BASE_URL}/users/me` : null,
      token || '',
    ],
    fetcher,
    {
      refreshInterval: 1000 * 60,
    },
  );

  useEffect(() => {
    if (!isLoading && user === null && swrUser != null && !isError(swrUser)) {
      dispatch({ type: 'user', payload: swrUser });
    }
    if (!isLoading && swrUser && isError(swrUser)) {
      dispatch({ type: 'user', payload: null });
      dispatch({ type: 'token', payload: null });
      window.location.reload();
    }
  }, [isLoading, user, swrUser]);

  const [announcements, setAnnouncements] = useState<Announcement[]>([]);

  const path = usePathname();

  useEffect(() => {
    const lsSignup = localStorage.getItem('signup');
    if (lsSignup && !user && !path.startsWith('/user')) {
      dispatch({ type: 'couponOpen', payload: true });
      localStorage.removeItem('signup');
    }

    window.onbeforeunload = function () {
      if (
        window.location.pathname.startsWith('/user/signup') &&
        !localStorage.getItem('loggedOut')
      ) {
        dispatch({ type: 'couponOpen', payload: true });
        return true;
      }
    };

    if (localStorage.getItem('loggedOut')) {
      localStorage.removeItem('loggedOut');
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [path]);

  useEffect(() => {
    (async () => {
      const announcements: Announcement[] = parentAnnouncements.filter((a) => {
        if (user && (path === '/' || path === '/my-sermons')) {
          return (
            (['Logged In', 'Both'] as UserStatus[]).includes(a.userStatus) &&
            (['Front Page', 'Everywhere'] as ShowOn[]).includes(a.showOn)
          );
        } else if (!user && (path === '/' || path === '/my-sermons')) {
          return (
            (['Not Logged In', 'Both'] as UserStatus[]).includes(
              a.userStatus,
            ) && (['Front Page', 'Everywhere'] as ShowOn[]).includes(a.showOn)
          );
        } else if (user) {
          return (
            (['Logged In', 'Both'] as UserStatus[]).includes(a.userStatus) &&
            (['Everywhere'] as ShowOn[]).includes(a.showOn)
          );
        } else if (!user) {
          return (
            (['Not Logged In', 'Both'] as UserStatus[]).includes(
              a.userStatus,
            ) && (['Everywhere'] as ShowOn[]).includes(a.showOn)
          );
        }
      });
      setAnnouncements(announcements);
    })().catch(console.error);
  }, [user, path]);

  const [calendlyIsOpen, setCalendlyIsOpen] = useState(false);

  if (path.endsWith('/print')) {
    return null;
  }

  const today = moment();
  const expirationDate = moment(user?.expirationDate);
  const isExpired = !expirationDate.isValid() || today.isAfter(expirationDate);

  return (
    <>
      <div className='fixed z-[51] w-full border-b border-gray-400 bg-white py-2 shadow-[1px_0_2px_#b6b5b5]'>
        <div className='flex w-full justify-between px-4 lg:mx-auto lg:max-w-6xl'>
          <div className='relative flex-1'>
            <Button
              type='button'
              className='mr-2 h-9 align-text-bottom text-lg'
              onClick={() => setMenuOpen(!menuOpen)}
              ref={menuButtonRef}
            >
              <BsList />
            </Button>
            <nav
              className={cx(
                'absolute left-0 top-9 z-50 w-fit border border-neutral-300 bg-white text-sm',
                {
                  hidden: !menuOpen,
                },
              )}
            >
              <ul className='grid grid-cols-1'>
                <li className='cursor-pointer text-sermons-dark hover:bg-neutral-100 hover:underline'>
                  <Link className='block px-4 py-1 pt-2' href='/this-week'>
                    This Week
                  </Link>
                </li>
                <li className='cursor-pointer text-sermons-dark hover:bg-neutral-100 hover:underline'>
                  <Link className='block px-4 py-1 pb-2' href='/contact-us'>
                    Contact Us
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className='flex flex-1 items-center justify-center gap-1 text-center text-lg text-sermons-dark'>
            <Link className='group' href='/'>
              {isBrowser &&
                !document.location.hostname.endsWith('lectionary.com') && (
                  <div className='relative -top-1'>
                    <span className='icon-SermonsLogo mr-1 hidden align-bottom lg:inline-block'></span>
                    <span className='icon-SermonsLogoText align-bottom group-hover:underline' />
                  </div>
                )}
              {isBrowser &&
                document.location.hostname.endsWith('lectionary.com') && (
                  <>
                    <Image
                      className='icon-SermonsLogo relative top-[-2px] mr-1 hidden lg:inline-block'
                      src={'/img/lectionary.com-icon.svg'}
                      width={18}
                      height={18}
                      alt=''
                    />
                    <span className='align-bottom font-extrabold uppercase group-hover:underline'>
                      Lectionary.com
                    </span>
                  </>
                )}
            </Link>
          </div>
          {!token && (
            <div className='flex-1 text-right'>
              <div>
                <Image
                  className='mr-[3px] mt-[-20px] inline-block cursor-pointer transition-all ease-in-out hover:[transform:scale(1.125)]'
                  src='/img/zoom-icon.png'
                  width={45}
                  height={45}
                  alt='Zoom'
                  onClick={() => setCalendlyIsOpen(true)}
                />
                <Link href='/user/signup'>
                  <Button className='mr-2 hidden h-9 align-text-bottom text-lg lg:inline-block'>
                    Signup
                  </Button>
                </Link>
                <LoginButton />
              </div>
            </div>
          )}
          {token && (
            <div className='relative flex-1 text-right'>
              <Image
                className={classNames(
                  'mt-[-20px] inline-block cursor-pointer transition-all ease-in-out hover:[transform:scale(1.125)]',
                  {
                    'mr-[10px]': isExpired || user?.isRenewal,
                    'mr-[3px]': !(isExpired || user?.isRenewal),
                  },
                )}
                src='/img/zoom-icon.png'
                width={45}
                height={45}
                alt='Zoom'
                onClick={() => setCalendlyIsOpen(true)}
              />
              {(isExpired || user?.isRenewal) && <RenewButton />}
              <Button
                className='h-9 align-text-bottom text-lg'
                onClick={() => setUserMenuOpen(!userMenuOpen)}
                ref={userMenuButtonRef}
              >
                <BsPersonFill className='inline lg:hidden' />
                <span className='hidden lg:inline'>
                  {user?.firstName} {user?.lastName}{' '}
                  <BsCaretDownFill className='inline-block' />
                </span>
              </Button>
              <nav
                className={cx(
                  'absolute right-0 top-9 z-50 border border-neutral-300 bg-white text-sm',
                  {
                    hidden: !userMenuOpen,
                  },
                )}
              >
                <ul className='grid grid-cols-1'>
                  <li className='cursor-pointer text-sermons-dark hover:bg-neutral-100 hover:underline'>
                    <Link className='block px-4 py-1 pt-2' href='/my-account'>
                      My Account
                    </Link>
                  </li>
                  <li className='cursor-pointer text-sermons-dark hover:bg-neutral-100 hover:underline'>
                    <Link
                      className='block px-4 py-1 pt-2'
                      href='/my-account?tab=defaults'
                    >
                      Defaults
                    </Link>
                  </li>
                  {!!user?.isAdmin && (
                    <li className='cursor-pointer text-sermons-dark hover:bg-neutral-100 hover:underline'>
                      <Link
                        className='block px-4 py-1 pb-2'
                        href='/admin/users'
                      >
                        Admin Dashboard
                      </Link>
                    </li>
                  )}
                  <li className='cursor-pointer text-sermons-dark hover:bg-neutral-100 hover:underline'>
                    <span
                      className='block px-4 py-1 pt-2'
                      onClick={(e) => {
                        e.preventDefault();
                        resetAppState();
                      }}
                    >
                      Logout
                    </span>
                  </li>
                </ul>
              </nav>
            </div>
          )}
        </div>
      </div>
      <div className='h-[56px]'></div>
      {tutorialOpen && (
        <>
          <div
            className='fixed inset-0 z-50 block bg-[rgba(0,0,0,.8)]'
            onClick={() => setTutorialOpen(false)}
          />
          <div
            className='relative z-50 mx-auto mt-16 w-full rounded bg-white p-4 text-center lg:max-w-4xl'
            id='tutorial_modal'
            tabIndex={-1}
            aria-labelledby='tutorial_modal'
            aria-modal='true'
            role='dialog'
          >
            <div className='relative'>
              <div className='text-center text-sermons-dark'>
                <span className='icon-SermonsLogo mr-1'></span>
                <span className='icon-SermonsLogoText'></span>
              </div>
              <button
                className='absolute -right-8 -top-8'
                onClick={() => setTutorialOpen(false)}
              >
                <Image
                  width={45}
                  height={45}
                  src='/img/close-btn.png'
                  alt='close'
                />
              </button>
            </div>
            <div className='text-center'>
              <p className='mb-0'>Welcome! We&apos;re glad you found us!</p>
              <p className='mb-0'>
                Want to know how Sermons.com works? Watch our tutorial.
              </p>
              <iframe
                className='mx-auto'
                width='766'
                height='431'
                src='https://www.youtube.com/embed/luhE1D-RayY'
                aria-label='YouTube video player'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              />
            </div>
          </div>
        </>
      )}
      {announcements.length > 0 && !path.startsWith('/admin') && (
        <div className='grid grid-cols-1 gap-2 p-4 !pt-2 pb-0 lg:mx-auto lg:max-w-6xl'>
          {announcements.map((announcement) => (
            <div
              key={announcement.id}
              className='rounded border border-neutral-300 bg-neutral-100 p-4'
              dangerouslySetInnerHTML={{ __html: announcement.announcement }}
            ></div>
          ))}
        </div>
      )}
      <Dialog
        className='rounded border border-neutral-300'
        isOpen={couponOpen}
        onBackdropClick={() => dispatch({ type: 'couponOpen', payload: false })}
      >
        <article>
          <header className='flex items-center justify-between border-b border-neutral-300 bg-neutral-100 p-4'>
            <h2 className='text-2xl'>Before You Go!</h2>
            <button
              type='button'
              onClick={() => dispatch({ type: 'couponOpen', payload: false })}
            >
              <BsXCircle />
            </button>
          </header>
          <section className='bg-white p-4'>
            <div className='mb-4 text-center'>
              <Image
                className='inline-block border-4 border-dashed border-black'
                src='/img/discount-coupon.png'
                width={323}
                height={244}
                alt='25% discount coupon'
              />
            </div>
            <p>
              Before you go we would like to offer you a 25% DISCOUNT to the
              annual membership. Join the tens-of-thousands of pastors who
              benefit from the best preaching resources. Simply click the Signup
              button below and the discount will be applied.
            </p>
          </section>
          <footer className='flex items-center justify-end border-t border-neutral-300 bg-neutral-100 p-4'>
            <Button
              className='mr-4 h-9'
              tagType='a'
              href='/user/signup?coupon=new-member-discount'
              onClick={() => dispatch({ type: 'couponOpen', payload: false })}
            >
              Signup
            </Button>
            <Button
              className='h-9'
              buttonType='flat-white'
              type='button'
              onClick={() => dispatch({ type: 'couponOpen', payload: false })}
            >
              Close
            </Button>
          </footer>
        </article>
      </Dialog>
      <CalendlyDialog
        calendlyIsOpen={calendlyIsOpen}
        setCalendlyIsOpen={setCalendlyIsOpen}
      />
    </>
  );
}
